/*eslint-disable*/
import React from "react";
import styles from "assets/css/footer.css";

// reactstrap components
import { Container, Row , Col} from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xl-3">
              <div className="footer-widget">
                <h4>Visit</h4>

                <a><span><div><i className="fa fa-home" aria-hidden="true"></i></div> 1860 SW Fountainview Blvd. <br></br>     Port Saint Lucie, FL 34986</span></a>
              </div>
            </div>

            <div className="col-sm-1">
              </div>
                <div className="col-xl-3">
                  <div className="footer-widget-content">
                    <h4>Stay in touch</h4>


                    <div className="footer-widget-content">
                      <a href="mailto:info@WillardDevelopment.com" className="contact-link blue"><i className="fa fa-mail-bulk" aria-hidden="true"></i>   Info@Willarddevelopment.com </a>

                      <div><br></br></div>
                      <a href="tel:7723403444" className="contact-link"><i className="fa fa-phone" aria-hidden="true"></i>   772.340.3444</a>
                      <div className="footer-social">
                        <ul>
                          <a>__________________________________________</a>
                          <div>
                          <li><a href="https://www.facebook.com/pages/category/Construction-Company/Willard-Development-Inc-254837794688114/"><i className="fab fa-facebook-square"></i></a></li>
                          <li><a href="https://twitter.com/WillardDevelop"><i className="fab fa-twitter"></i></a></li>
                          <li><a href="https://www.instagram.com/willarddevelopmentinc/"><i className="fab fa-instagram"></i></a></li>
                          <li><a href="https://www.youtube.com/channel/UCmmmebZjDlNpqaOVKHZr0ZA"><i className="fab fa-youtube"></i></a></li>
                          </div>
                        </ul>
                      </div>



                    </div>



                  </div>
                </div>

            <div className="col-sm-1">
            </div>
            <div className="col-xl-3">





                    <ul className="opening-time2">

                      <li><h4>Opening Hours</h4><p><strong><i className="fa fa-check"></i> &nbsp;Mon-Fri : </strong> 9am - 5pm</p></li>
                    </ul>
                    <ul className="opening-time">
                      <li><p><strong><i className="fa fa-times"></i> &nbsp; Sat-Sun : </strong> Closed</p></li>
                    </ul>




            </div>





          </div>

        </div>


      </footer>
    </>
  );
}

export default DefaultFooter;
