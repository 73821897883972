/*eslint-disable*/
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {AppBar, Toolbar} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// reactstrap components
import {img, div, Container, Button} from "reactstrap";
import {IconButton} from "@material-ui/core";
// core components

const useStyles = makeStyles((theme) => ({
  title: {
    'margin-top': '5px',
    'margin-bottom': 0,
  },
}));



function IndexHeader() {

  const classes = useStyles();
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
            "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
      <>
        <div className="page-header page-header-small">
          <div
              className="page-header-image"
              style={{
                backgroundImage: "url(" + require("assets/img/ReceptionDeskCompressed.jpg") + ")",
              }}
              ref={pageHeader}
          >

          </div>


          <div className="content-center">
            {/**
            <Container>
              <h1 className={classes.title}>Welcome to <br/>
              Willard Development Inc. </h1>
              <IconButton>
                <ExpandMoreIcon className={classes.goDown} />
              </IconButton>
              <div className="text-center">
                <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
                <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter"></i>
                </Button>
                <Button
                    className="btn-icon btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-google-plus"></i>
                </Button>
              </div>
            </Container>
            */}
          </div>

        </div>
      </>
  );
}

export default IndexHeader;
