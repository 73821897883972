import React from "react";

import emailjs from "emailjs-com";


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col, Row,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter";

function Contact() {
    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_0s2arb4', 'template_eodbd14', e.target, 'user_gZ383AXTPpAkynCYDBuIn')
            .then((result) => {
                console.log(result.text);
                alert(`Willard Development received your message`);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }


    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <ExamplesNavbar />

            <div className="page-header clear-filter" filter-color="blue">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/florida.jpg") + ")",
                    }}
                ></div>
                <div className="content">

                </div>

                
            </div>
            <div className="section section-contact-us text-center">
                <Container>
                    <h2 className="title">Contact Us</h2>
                    <p className="description">Your project is very important to us.</p>
                    <Row>
                        <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                            <form onSubmit={sendEmail}>
                                <InputGroup
                                    className={
                                        "input-lg" + (firstFocus ? " input-group-focus" : "")
                                    }
                                >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="now-ui-icons users_circle-08"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="First Name"
                                        type="text"
                                        name="name"
                                        onFocus={() => setFirstFocus(true)}
                                        onBlur={() => setFirstFocus(false)}
                                    ></Input>
                                </InputGroup>
                                <InputGroup
                                    className={
                                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                                    }
                                >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="now-ui-icons users_single-02"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Last Name"
                                        type="text"
                                        name="name2"
                                        onFocus={() => setLastFocus(true)}
                                        onBlur={() => setLastFocus(false)}
                                    ></Input>
                                </InputGroup>
                                <InputGroup
                                    className={
                                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                                    }
                                >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="now-ui-icons ui-1_email-85"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Email"
                                        type="text"
                                        name="email"
                                        onFocus={() => setLastFocus(true)}
                                        onBlur={() => setLastFocus(false)}
                                    ></Input>
                                </InputGroup>
                                <InputGroup
                                    className={
                                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                                    }
                                >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>

                                            <i className="fa fa-phone"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Phone Number"
                                        type="number"
                                        name="phone"
                                        onFocus={() => setLastFocus(true)}
                                        onBlur={() => setLastFocus(false)}
                                    ></Input>
                                </InputGroup>
                                <InputGroup
                                    className={
                                        "input-lg" + (lastFocus ? " input-group-focus" : "")
                                    }
                                >
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="now-ui-icons arrows-1_minimal-right"></i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Subject"
                                        type="text"
                                        name="subject"
                                        onFocus={() => setLastFocus(true)}
                                        onBlur={() => setLastFocus(false)}
                                    ></Input>
                                </InputGroup>
                                <div className="textarea-container">
                                    <Input
                                        cols="80"
                                        name="message"
                                        placeholder="Type a message..."
                                        rows="4"
                                        type="textarea"
                                    ></Input>
                                </div>
                                <div className="send-button">
                                    <Button
                                        block
                                        className="btn-round"
                                        color="info"
                                        type="submit"
                                        size="lg"
                                    >
                                        Send Message
                                    </Button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DefaultFooter />
        </>
    );
}

export default Contact;
