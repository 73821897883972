import React from "react";
import { FaBuilding } from "react-icons/fa";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
    ToastHeader,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import { enableBodyScroll } from "body-scroll-lock";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Paella from "../index-sections/Paella";
import Paella2 from "../index-sections/Paella2";
import Paella3 from "../index-sections/Paella3";
import Paella4 from "../index-sections/Paella4";


function ProfilePage() {
  const [pills, setPills] = React.useState("1");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="section">
          <Container>
            {/**<div className="button-container">
              <Button className="btn-round" color="info" size="lg">
                Follow
              </Button>
              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip515203352"
                size="lg"
              >
                <i className="fab fa-twitter"></i>
              </Button>

              <Button
                className="btn-round btn-icon"
                color="default"
                id="tooltip340339231"
                size="lg"
              >
                <i className="fab fa-instagram"></i>
              </Button>

            </div>
             **/}
              <h2 style={{color: "#c9d0d4", fontFamily: "Helvetica Neue, sans-serif", fontSize: "46px", fontWeight: "100", lineHeight: "50px", letterSpacing: "1px", padding: "0 0 40px", borderBottom: "double #555"}} className="title">South Florida Properties</h2>
              <h4  style={{ color: "#000000", textAlign: "center", fontFamily: 'Verdana, sans-serif', fontSize: "16px", lineHeight: "26px", padding: "5%", margin: "0"}}>
              At Willard Development our goal is to provide quality services with a
              focus on reinvesting into the local community. We strive to make sure our projects
              and local involvement can contribute to making Port St. Lucie a great place to live,
              work and play.
            </h4>
            <Row>

                {/**
              <Col className="ml-auto mr-auto" md="6">
                <div className="nav-align-center">
                  <Nav
                    className="nav-pills-info nav-pills-just-icons"
                    pills
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        <i className="now-ui-icons media-1_camera-compact"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        <i className="now-ui-icons gestures_tap-01"></i>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        <i> <FaBuilding /></i>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              **/}
                {/**
                <TabContent className="gallery" activeTab={"pills" + pills}>
                <TabPane tabId="pills1">**/}
                  <Col className="ml-auto mr-auto" md="15">
                    <Row className="collections">

                        <Col md="3">
                          <Paella image={require('assets/img/4. 1860 Exterior of Bldg-min.jpg')}
                          about="This is our company"
                          subheader="1860 SW Fountainview"/>
                          <br></br>
                          <br></br>
                        </Col>
                        <Col md="3">
                          <Paella image={require('assets/img/3. LakeWhitney Phase III-min.jpg')}
                                  about="This is our company"
                                  subheader="Lake Whitney Phase III"

                          />
                          <br></br>
                          <br></br>
                        </Col>
                        <Col md="3">
                          <Paella image={require('assets/img/5. 10570 S US Hwy 1 Exterior-min.jpg')}
                                  about="This is our company"
                                  subheader="10570 US HighWay 1"/>
                          <br></br>
                          <br></br>
                        </Col>
                        <Col md="3">
                          <Paella image={require('assets/img/1. Lake Whitney Phase I-min.jpg')}
                                  about="This is our company"
                                  subheader="Lake Whitney Phase I"/>
                          <br></br>
                          <br></br>
                        </Col>


                    </Row>
                  </Col>
                {/**
                </TabPane>
                <TabPane tabId="pills2">
                **/}
                  <Col className="ml-auto mr-auto" md="15">
                    <Row className="collections">

                        <Col md="3">
                            <Paella image={require('assets/img/2. Lake Whitney Phase II-min.jpg')}
                                    about="This is our company"
                                    subheader="Lake Whitney Phase II"/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/6. Woodbine Commons Exterior-min.jpg')}
                                    about="This is our company"
                                    subheader="Woodbine Commons"/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/7. Boynton 53 Land (Boynton Beach)-min.jpg')}
                                    about="This is our company"
                                    subheader="   Boynton 53 LLC  "/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/8. 10th Street Land (Deerfield Beach)-min.jpg')}
                                    about="This is our company"
                                    subheader="10th Street 416, LLC"/>
                            <br></br>
                            <br></br>
                        </Col>


                    </Row>
                  </Col>
                {/**
                </TabPane>
                <TabPane tabId="pills3">
                **/}
                  <Col className="ml-auto mr-auto" md="15">
                    <Row className="collections">

                        <Col md="3">
                            <Paella image={require('assets/img/9. Sunbelt Rentals Property-min.jpg')}
                                    about="This is our company"
                                    subheader="Sunbelts Rentals Building"/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/10. Sarasota Business Center II (Porter Road)-min.JPG')}
                                    about="This is our company"
                                    subheader="Sarasota Business Center II"/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/11. Sarasota Commerce Park (787 & 797 Commerce Road)-min.jpg')}
                                    about="This is our company"
                                    subheader="Sarasota Commerce Park"/>
                            <br></br>
                            <br></br>
                        </Col>
                        <Col md="3">
                            <Paella image={require('assets/img/Glades Sign.gif')}
                                    about="This is our company"
                                    subheader="Glades Commerce Center (Future Project)"/>
                            <br></br>
                            <br></br>
                        </Col>


                    </Row>
                  </Col>
                {/**}
                </TabPane>
              </TabContent>
                **/}
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ProfilePage;
