import React, { Component } from "react";
// reactstrap components
import {
    Container,
    Row,
    Col,
    Carousel,
    CarouselItem,
    CarouselIndicators,
} from "reactstrap";
import { Scrollbars } from 'react-custom-scrollbars';

// core components
import { MDBBtn, MDBCollapse } from "mdbreact";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

class CollapsePage extends Component {
    state = {
        collapseID: ""
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    render() {
        const mystyle = {
            fontFamily: 'Cambria',
            fontSize: "13px",


            color: "black",
            padding: "15px",

            fontWeight: "500",
            textAlign: "justify"
        };
        const containerStyle = {
            width: "400px"

        };
        return (
            <>
                <Container style={containerStyle}>


                <IconButton

                    color="white"
                    onClick={this.toggleCollapse("basicCollapse")}
                    style={{ marginBottom: "1rem", textAlign: "justify", padding: "10%", display: "block"}}
                >
                    <ExpandMoreIcon />
                </IconButton>
                <MDBCollapse id="basicCollapse" isOpen={this.state.collapseID}>


                    <Scrollbars
                        style={{ height: 300 }}>
                        <p style={mystyle}>
                            {this.props.title}
                        </p>
                    </Scrollbars>

                </MDBCollapse>
                </Container>
            </>
        );
    }
}

export default CollapsePage;