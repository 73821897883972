import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "#002275",
    },
}));

export default function RecipeReviewCard() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <i className="fas fa-wrench"></i>
                    </Avatar>
                }

                title="Construction & Development"
            />
            <CardMedia
                className={classes.media}
                image={require('assets/img/Development shutterstock_5121591.jpg')}
                title="Paella dish"
            />
            {/*
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Willard Development is a privately held real estate firm
                </Typography>
            </CardContent>
            */}
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        <h4  style={{ color: "#000000", textAlign: "center", fontFamily: 'Verdana, sans-serif', fontSize: "16px", lineHeight: "26px", margin: "0"}}>
                        Willard Development is a commercial construction & development company
                        in the treasure coast. Our name has become synonymous withhigh quality
                        commercial real estate projects in the market. Willard offers a full
                        range of in-house construction & development services. Our firm is a
                        licensed general contractor in the state of Florida. All construction is
                        supervised and coordinated by our own staff and we pride ourselves in
                        using the most reputable local firms to handle every aspect of development.
                        Handling the general contracting in-house allows us to control the timing,
                        cost, and quality of our projects. This provides for the most economical
                        lease rates and/or purchase prices for our clients. We take pride in that
                        we can fast track a project without having to sacrifice quality or cost.
                        We also have extensive experience in providing interior medical and office
                        buildouts for third party clients.
                        </h4>
                    </Typography>

                </CardContent>
            </Collapse>
        </Card>
    );
}