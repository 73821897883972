import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ModalImage from "react-modal-image";
import {ToastHeader} from "reactstrap";

import ReactMedium from 'react-medium-zoom';
import CardMedia from "@material-ui/core/CardMedia";
import React, { useCallback, useState } from "react";
import {Controlled as ControlledZoom } from "react-medium-image-zoom";
import 'react-medium-image-zoom/dist/styles.css';


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 500,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));



export default function RecipeReviewCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);


    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


        const [isZoomed, setIsZoomed] = React.useState(false)

        const handleImgLoad = useCallback(() => {
            setIsZoomed(true)
        }, [])

        const handleZoomChange = useCallback(shouldZoom => {
            setIsZoomed(shouldZoom)
        }, [])






    return (



        <Card className={classes.root}>


            <ModalImage large={"assets/img/5. 10570 S US Hwy 1 Exterior-min.jpg"} />
            {/*<ReactMedium style={{ width: '100%', maxWidth: '100%' }} src={(props.image)} />*/}
            <h4 style={{fontFamily: 'Cambria', fontSize: "17px", paddingLeft: "40px", fontWeight: "100"}}>{props.subheader}</h4>
            <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>

                <img
                    style={{ width: '100%', maxWidth: '100%' }}
                    alt="that wanaka tree"
                    src={(props.image)}

                    width="500"
                />
            </ControlledZoom>


            {/*<CardMedia*/}
            {/*    className={classes.media}*/}
            {/*    image={(props.image)}*/}
            {/*    title="Paella dish"*/}
            {/*    style={{width: "100%"}}*/}
            {/*/>*/}
            {/*
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Willard Development is a privately held real estate firm
                </Typography>
            </CardContent>
            <CardActions disableSpacing>

                <IconButton aria-label="share">
                    <ShareIcon />
                </IconButton>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>

                    <Typography paragraph>

                        {props.about}

                    </Typography>

                </CardContent>
            </Collapse>
            */}
        </Card>


    );
}