import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "#002275",
    },
}));

export default function RecipeReviewCard() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <i className="fas fa-tasks"></i>
                    </Avatar>
                }

                title="Property Management"

            />
            <CardMedia
                className={classes.media}
                image={require('assets/img/Property Management shutterstock.jpg')}
                title="Paella dish"
            />
            {/*
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Willard Development is a privately held real estate firm
                </Typography>
            </CardContent>
            */}
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        <h4  style={{ color: "#000000", textAlign: "center", fontFamily: 'Verdana, sans-serif', fontSize: "16px", lineHeight: "26px", margin: "0"}}>
                        Our licensed property management personnel have a proven track record of
                        achieving the highest occupancy rates, as well as the best maintained
                        facilities in the markets we service. We currently manage approximately
                        750 thousand square feet of commercial real estate. Our clients are comprised
                        of medical and professional office business parks and industrial centers.
                        We serve as the management and leasing agent for a few small investors in
                        commercial real estate in South East Florida. The team has extensive
                        knowledge of budgeting, financial planning and analysis, landlord lease
                        negotiations, and owner reporting. Our team is hands on in every aspect of
                        the day-to-day operations of the properties to minimize operating costs,
                        improve efficiencies, and enhance customer service in order to protect the
                        value of each investment.
                        </h4>
                    </Typography>

                </CardContent>
            </Collapse>
        </Card>
    );
}