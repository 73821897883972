import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <Container></Container>
        {/*
            <div className="section section-images">


                <Container className="first-image">
                    <img
                        alt="..."
                        src={require("assets/img/project-management.png")}
                    ></img>
                </Container>


            </div>
*/}
    </>
  );
}

export default Images;
