import React from "react";
import ScriptTag from 'react-script-tag';

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components


import ExamplesNavbar2 from "../../components/Navbars/ExamplesNavbar2";
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Tabs from "../index-sections/Tabs";
import emailjs from "emailjs-com";
import CollapsePage from "../index-sections/CollapsePage";

function LandingPage() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_5gow839', 'template_5975gv9', e.target, 'user_lxo3gBJKIzljDQ7ougdyd')
        .then((result) => {
          console.log(result.text);
          alert(`Willard Development received your message`);
        }, (error) => {
          console.log(error.text);
        });
    e.target.reset()
  }



  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ExamplesNavbar2 />
      <div className="wrapper">
        <LandingPageHeader />

        <div className="section section-team text-center">
          <Container>
            <h2 style={{color: "#c9d0d4", fontFamily: "Helvetica Neue, sans-serif", fontSize: "46px", fontWeight: "100", lineHeight: "50px", letterSpacing: "1px", padding: "0 0 40px", borderBottom: "double #555"}} className="title">The Senior Team</h2>
            <div className="team">

              <Row>
                <Col md="5">
                  <div className="team">

                    <img
                        style={{width: "300px"}}
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/Danny Will.-2344_RT_pp copy(2).jpg")}
                    ></img>
                    <h4 className="title">Danny Willard</h4>
                    <p className="category text-info">CEO</p>

                      <CollapsePage title="The Willard family has been in the construction business of residential and commercial buildings
for more than 38 years. By 1989, Mr. Danny L. Willard and his brother Mr. Alan “Bruce” Willard
became partners and formed a shell contracting company known as Willard Brothers Construction
(WBC). The company expanded from a small local Broward County shell contracting company, with a
few employees, to a state-wide operation consisting of several divisions with a staff more than 175
people in the main and regional offices and 1,500 field crew members. WBC had a client list of more
than 25 regional and national production homebuilders (G. L. Homes, Engle Homes, WCI
Communities, Lennar, Centex, D. R. Horton, Minto, Centerline, Prime Homebuilders, Pulte and
Divosta and others). The annual revenues for 2002 exceeded $100 million. In 2003, Building
Materials Holding Corporation (BMHC), a San Francisco based company and a leader in the
construction industry purchased a 60% interest in Willard Brothers Construction. This entity
operated as a business unit of BMHC, under the name WBC Construction, LLC and was one of the
largest shell contracting companies in the state of Florida reaching nearly $300 million in annual
revenues. In 2006 BMHC purchased the remaining 40% interest in the former Willard Brothers
Construction and changed the operating name to SelectBuild.
Mr. Willard has expanded his construction knowledge and experience from the construction trade
companies to development, construction, and management of office space generating reoccurring
income properties for investor groups. Willard Development, Inc. is focused on markets such as
custom office, industrial, and medical commercial projects. The company recently completed the
development and stabilization of a 200k square foot Medical and Professional Office Park, known as
Lake Whitney Professional Park in Port St. Lucie, Florida. He has also been actively involved in the
acquisition of several underperforming and undermanaged commercial real estate properties in the
St. Lucie market and utilizes his teams expertise to reposition this assets through effective
management and capitalization to stabilize and improve cash-on-cash asset performance for
improved valuation.
Mr. Willard also actively supports the Susan G. Komen Breast Cancer Society and the Broward
Partnership for The Homeless (BPHI) in Ft. Lauderdale, Florida."/>



                  </div>
                  <br></br><br></br>
                </Col>
                <Col md="2"></Col>
                <Col md="5">
                  <div className="team">
                    <img
                        style={{width: "300px"}}
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/Maria R Gonzalez-min.jpg")}
                    ></img>
                    <h4 className="title">Maria R. Gonzalez</h4>
                    <p className="category text-info">CFO</p>

                      <CollapsePage  title="Maria Rojas Gonzalez has over 30 years experience as an accounting and finance professional. This
includes over 25 years in the construction, development, and real estate management accounting
arena. Her experience encompasses large Fortune 100 publicly traded companies, as well as, small
privately held companies, including Honeywell Corporation, Bluegreen Resorts, Trammell Crow and
SelectBuild. Her areas of expertise include managing all the fiscal aspects of a company, including,
analyzing and evaluating financial performance to provide senior management with the appropriate
data to make informed financial and strategic decisions.

Maria R. Gonzalez holds a Bachelor of Business Administration in Accounting from Loyola University
and a Master of Business Administration (MBA) from Baker University. She is also a licensed
Community Association Manager, a Licensed Real Estate Broker, and a Notary Public."/>


                  </div>
                  <br></br><br></br>
                </Col>
                <Col md="2"></Col>

              </Row>
              <Row>


                <Col md="5">
                  <div className="team">
                    <img
                        style={{width: "300px"}}
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/Hector Cruz-min.jpg")}
                    ></img>
                    <h4 className="title">Hector Cruz</h4>
                    <p className="category text-info">Director of Construction</p>

                      <CollapsePage  title="Mr. Hector Cruz has been in the construction industry for over 30 years. He has had progressive
design/construction and building management experience. He is accustomed to handling all aspects
of the construction process. During his career Mr. Cruz has directed the construction process for
more than $1 billion in development projects. His approach to construction management involves
establishing and maintaining great relationships with owners, design professionals, contractors, as
well as, building and local officials.

Mr. Cruz has extensive experience in planning and management of the daily operations of
residential, as well as commercial construction projects. In this capacity he has managed all facets of
the project management process, including estimating contract negotiations, permitting, land
development, sales, and material and labor procurement. He manages project schedules, quality
control standards, safety controls, and project budgets to ensure projects are completed within the
established guidelines. His ability to effectively manage projects and building maintenance issues
creates a collaborative relationship with our clients and tenants alike."/>


                  </div>
                  <br></br><br></br>
                </Col>
                <Col md="2"></Col>


                <Col md="5">

                  <div className="team">
                    <img
                        style={{width: "300px"}}
                        alt="..."
                        className="rounded-circle img-fluid img-raised"
                        src={require("assets/img/Jamie Willard_RT-2306(1).jpg")}
                    ></img>
                    <h4 className="title">Jamie Willard</h4>
                    <p className="category text-info">VP of Operations</p>

                      <CollapsePage title="Mrs. Jamie Willard has over 10 years’ experience as an operations and finance professional. Her
experience includes real estate acquisitions/sales, development, and property management in both
the commercial and residential industry. Jamie has excellent collaborative skills in working with
departmental managers to create budgets and develop business plans. She possesses proven
leadership skills, as well as a strong ability to problem solve and make sound decisions that
encourage corporate growth. Jamie Willard holds a bachelor’s degree in Business Administration
with a concentration in Finance. She is also a Licensed Realtor and a Notary Public."/>



                  </div>

                </Col>
                <br></br><br></br>

              </Row>
            </div>
          </Container>
        </div>
        {/**
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Contact Us</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <form onSubmit={sendEmail}>
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons users_circle-08"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="First Name..."
                    type="text"
                    name="name"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    name="email"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="message"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    type="submit"
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </form>
              </Col>
            </Row>
          </Container>
        </div>
         **/}
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
