import React from "react";
import Paella from "../index-sections/Paella";
import Paella2 from "../index-sections/Paella2";
import PaellaOriginal from "../index-sections/PaellaOriginal";
import PaellaOriginal1 from "../index-sections/PaellaOriginal1"
import PaellaOriginal2 from "../index-sections/PaellaOriginal2"
import Home from '../../assets/data/Home'


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col,
    Row
} from "reactstrap";




// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import DefaultFooter from "../../components/Footers/DefaultFooter";

function Services() {
    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);
    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);
    return (
        <>
            <ExamplesNavbar />
            <div className="page-header clear-filter" filter-color="blue">
                <div
                    className="page-header-image"
                    style={{
                        backgroundImage: "url(" + require("assets/img/stuart.jpg") + ")",
                    }}
                ></div>


            </div>
            <br></br>
            <br></br>
                <div className="content">
                <Container>
                    <Home />
                    


                </Container>
            </div>


            <br></br>
            <br></br>

            <div>
            <DefaultFooter />
            </div>
        </>
    );
}

export default Services;
