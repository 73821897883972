import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: "#002275",
    },
}));

export default function RecipeReviewCard() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        <i className="fas fa-chart-line"></i>
                    </Avatar>
                }

                title="Acquisitions"

            />
            <CardMedia
                className={classes.media}
                image={require('assets/img/Acquisition & Due Diligence shut.jpg')}
                title="Paella dish"
            />
            {/*
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    Willard Development is a privately held real estate firm
                </Typography>
            </CardContent>
            */}
            <CardActions disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: expanded,
                    })}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p" paragraph>
                        <h4  style={{ color: "#000000", textAlign: "center", fontFamily: 'Verdana, sans-serif', fontSize: "16px", lineHeight: "26px", margin: "0"}}>
                        Our services encompass analyzing existing real estate opportunities.
                        Our investment criteria include all categories of real estate:
                        professional and medical office, industrial, and retail. With over
                        30 years in construction and 15 years of experience in developing,
                        leasing, and managing these types of assets, our organization can maintain,
                        improve, and add value to your real estate assets. We assist investors with
                        identifying potential commercial real estate opportunities and taking them
                        every step of the way through acquisition with an array of in-house and local
                        contractors to assist with a full range due-diligence process to ensure the
                        opportunities meet the investor/partner criteria.
                        </h4>

                    </Typography>

                </CardContent>
            </Collapse>
        </Card>
    );
}